/******* screen-large.less *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.desk {
  max-width: 77.14285714%;
}
.moodcontent .desk {
  padding-left: 0;
}
@media only screen and (max-width: 1409px) {
  .moodcontent .desk {
    max-width: calc(100% - 60px);
    padding-left: 150px;
  }
}
#expo div.link .prev,
#expo div.link .next {
  width: 40px;
  height: 40px;
}
#expo div.link .prev.cb-slide-previous,
#expo div.link .next.cb-slide-previous {
  right: 80px;
}
.footer-top .h2email {
  margin-top: 90px;
  margin-bottom: 60px;
}
.footer-bottom .open {
  float: left;
  width: 100%;
  margin-top: 60px;
}
.footer-bottom .open h2 {
  float: left;
  width: 51.38888889%;
  margin-bottom: 60px;
}
.footer-bottom .open .opentime {
  margin-top: 15px;
}
.footer-bottom .info {
  float: left;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}
.cb-layout2 h1 {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.33333333;
}
.cb-layout1 h1,
h2 {
  font-size: 46px;
  font-size: 4.6rem;
}
.farnorth h2 {
  font-size: 74px;
  font-size: 7.4rem;
}
.area,
.area.south {
  width: 100%;
}
.area > .unit,
.area.south > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area .foot,
.area.south .foot {
  margin-right: 1.35135135%;
  margin-left: 1.35135135%;
}
.area .part,
.area.south .part {
  margin-right: 1.35135135%;
  margin-left: 1.35135135%;
  width: 97.2972973%;
}
.area .tiny,
.area.south .tiny {
  width: 47.2972973%;
}
.area > .slim,
.area.south > .slim {
  width: 50%;
}
.area > .slim .foot,
.area.south > .slim .foot,
.area > .slim .part,
.area.south > .slim .part {
  margin-right: 2.7027027%;
  margin-left: 2.7027027%;
}
.area > .slim .part,
.area.south > .slim .part {
  width: 94.59459459%;
}
.area > .slim .tiny,
.area.south > .slim .tiny {
  width: 44.59459459%;
}
.area,
.area.south {
  width: 102.77777778%;
  margin-left: -1.38888889%;
}
.area .unit.slim.pure .part.pict.tall,
.area .unit.slim.seam .part.pict.tall {
  width: 46.78571429vw;
}
.area .unit.slim.pure .part.pict.tiny,
.area .unit.slim.seam .part.pict.tiny {
  width: 94.59459459%;
}
.area .unit.slim.pure .part.pict.tall {
  float: right;
}
.area .unit.wide.seam .body {
  width: 123.42342342%;
  margin-left: 50%;
  transform: translateX(-50%);
}
.area .unit.wide.seam .part.tall {
  width: 78.83211679%;
  margin-left: 10.58394161%;
  margin-right: 10.58394161%;
}
.area .unit.wide.seam .part.tiny {
  width: 47.81021898%;
  margin-left: 1.09489051%;
  margin-right: 1.09489051%;
}
#view .unit.flat {
  height: 38.57142857vw;
}
.unit.flat {
  margin-top: 45px;
  margin-bottom: 45px;
}
.unit.flat.slim {
  width: 100%;
}
.unit.flat .body:before {
  right: calc(100% - 330px);
}
.unit.flat .part {
  width: 97.2972973%;
  margin-left: 1.35135135%;
  margin-right: 1.35135135%;
}
.unit.flat .part.text {
  margin-top: 30px;
}
.unit.flat .part:not(.pict) {
  width: 330px;
}
.unit.flat .part.pict {
  height: 38.57142857vw;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 60px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -510px;
  width: 1020px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*# sourceMappingURL=./screen-large.css.map */